import React, { memo } from "react";
import {
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Refresh } from "@material-ui/icons";
import { BalancePayoutStatus } from "../../../graphql/types/global";

const allStatuses = Object.values(BalancePayoutStatus).sort();

interface FilterProps {
  status: BalancePayoutStatus | null;
  loading: boolean;
  onRequestRefetch: () => any;
  onRequestSetStatus: (types: BalancePayoutStatus | null) => any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    label: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      display: "block",
    },
    firstRow: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginBottom: theme.spacing(1),
    },
    textField: {
      margin: 0,
    },
    select: {
      minWidth: 140,
      marginRight: theme.spacing(1),
    },
  })
);

const PayoutRequestsFilter = memo<FilterProps>(
  ({ status, loading, onRequestRefetch, onRequestSetStatus }) => {
    const classes = useStyles();

    return (
      <div className={classes.container}>
        <div className={classes.firstRow}>
          <IconButton
            aria-label="refresh"
            onClick={onRequestRefetch}
            className={classes.icon}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size="24px" />
            ) : (
              <Refresh fontSize="default" />
            )}
          </IconButton>

          <FormControl
            variant="outlined"
            size="small"
            margin="dense"
            className={classes.select}
          >
            <InputLabel>Status</InputLabel>
            <Select
              value={status || ""}
              onChange={(evt) => {
                const value: string = (evt.target.value as any) || "";
                if (value) {
                  onRequestSetStatus(value as BalancePayoutStatus);
                } else {
                  onRequestSetStatus(null);
                }
              }}
              label="Status"
            >
              <MenuItem key="nothing" value="">
                Any status
              </MenuItem>
              {allStatuses.map((status) => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
    );
  }
);

export default PayoutRequestsFilter;
