import React, { memo, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";
import { OmitType } from "../../../utils/omitType";
import { formatCurrency } from "../../../utils/formatCurrency";
import { TimestampDisplay } from "../../../components/TimestampDisplay";
import { allBalancePayoutBankAccountRequests_allBalancePayoutBankAccountRequests } from "../../../graphql/types/allBalancePayoutBankAccountRequests";
import {
  Box,
  CircularProgress,
  Collapse,
  IconButton,
  Switch,
  Typography,
} from "@material-ui/core";
import { BalancePayoutStatus } from "../../../graphql/types/global";
import useQueryWithPreviousData from "../../../utils/useQueryWithPreviousData";
import {
  balancePayoutBankAccountRequestDetails,
  balancePayoutBankAccountRequestDetailsVariables,
} from "../../../graphql/types/balancePayoutBankAccountRequestDetails";
import { BALANCE_PAYOUT_BANK_ACCOUNT_REQUEST_DETAILS } from "../../../graphql/queries.gql";
import { dateAndTime } from "../../../utils/formatDate";
import { LabelledChip } from "../../../components/LabelledChip";

type Row = OmitType<
  allBalancePayoutBankAccountRequests_allBalancePayoutBankAccountRequests["requests"][number]
>;

interface HeadCell {
  id: keyof Row;
  label: string;
  type: "string" | "date" | "status" | "amount" | "account" | "user";
}

const headCells: HeadCell[] = [
  { id: "id", type: "string", label: "ID" },
  { id: "requestedByUserConnection", type: "user", label: "User" },
  { id: "requestedAt", type: "date", label: "Requested" },
  { id: "completedAt", type: "date", label: "Completed" },
  { id: "balanceTotalAmount", type: "amount", label: "Amount" },
  {
    id: "balancePayoutBankAccountConnection",
    type: "account",
    label: "Account",
  },
  { id: "status", type: "status", label: "Status" },
];

export type OnRequestPage = (page: number) => any;
export type OnRequestPageSize = (pageSize: number) => any;
export type OnRequestSetCompleted = (args: {
  balancePayoutRequestId: string;
  isCompleted: boolean;
}) => Promise<any>;

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
}

interface TableProps
  extends OmitType<allBalancePayoutBankAccountRequests_allBalancePayoutBankAccountRequests> {
  loading: boolean;
  onRequestRefetch: () => any;
  onRequestPage: OnRequestPage;
  onRequestPageSize: OnRequestPageSize;
  onRequestSetCompleted: OnRequestSetCompleted;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  return (
    <TableHead>
      <TableRow>
        <TableCell />
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={cellAlign(headCell)}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const cellAlign = (cell: HeadCell) =>
  cell.type === "amount" ? "right" : "left";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      flex: 1,
      marginBottom: theme.spacing(2),
      maxWidth: "calc(100vw - 34px)",
    },
    table: {},
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    noWrapCell: {
      whiteSpace: "nowrap",
    },
    fullWith: {
      width: "100%",
    },
    bankLogo: {
      maxWidth: 50,
      maxHeight: 30,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    expandButtonCell: {
      width: "44px",
      paddingRight: 0,
      paddingLeft: "8px",
    },
    userDetails: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
      display: "flex",
      "& > *": {
        marginRight: theme.spacing(1),
      },
    },
  })
);

const AccountView = ({
  account,
  styles,
}: {
  account: Row["balancePayoutBankAccountConnection"];
  styles: ReturnType<typeof useStyles>;
}) => {
  return (
    <Box>
      <img
        src={account.logoUrl}
        alt={account.bankName}
        className={styles.bankLogo}
      />
      {account.bankName} {account.fullAccountNumber}
    </Box>
  );
};

const StatusView = ({
  row,
  onRequestSetCompleted,
  loading,
}: {
  row: Row;
  onRequestSetCompleted: OnRequestSetCompleted;
  loading: boolean;
}) => {
  return (
    <Box display="flex" flexDirection="row" alignItems="center" px={1}>
      <Typography variant="body2">{row.status}</Typography>
      <Switch
        disabled={loading}
        color="primary"
        name="isCompleted"
        checked={row.status === BalancePayoutStatus.completed}
        onChange={() =>
          onRequestSetCompleted({
            balancePayoutRequestId: row.id,
            isCompleted: row.status !== BalancePayoutStatus.completed,
          })
        }
        size="small"
      />
    </Box>
  );
};

const PayoutRow = ({
  row,
  classes,
  onRequestSetCompleted,
  loading,
}: {
  row: Row;
  classes: ReturnType<typeof useStyles>;
} & Pick<TableProps, "onRequestSetCompleted" | "loading">) => {
  const [open, setOpen] = useState(false);
  const [details, { loading: detailsLoading }] = useQueryWithPreviousData<
    balancePayoutBankAccountRequestDetails,
    balancePayoutBankAccountRequestDetailsVariables
  >(
    BALANCE_PAYOUT_BANK_ACCOUNT_REQUEST_DETAILS,
    "balancePayoutBankAccountRequest",
    {
      variables: { balancePayoutRequestId: row.id },
      skip: !open,
    }
  );

  const renderCell = (cell: HeadCell, row: Row) => {
    switch (cell.type) {
      case "user":
        return (
          <div>
            <Typography variant="caption">
              {row.requestedByUserConnection.displayName}
            </Typography>
            <Typography variant="body2">
              {row.requestedByUserConnection.mobilePhoneNumber}
            </Typography>
          </div>
        );
      case "date":
        return <TimestampDisplay timestamp={row[cell.id] as string} />;
      case "account":
        return (
          <AccountView
            account={row.balancePayoutBankAccountConnection}
            styles={classes}
          />
        );
      case "amount":
        return formatCurrency(row.balanceTotalAmount);
      case "status":
        return (
          <StatusView
            row={row}
            onRequestSetCompleted={onRequestSetCompleted}
            loading={loading}
          />
        );
      default:
        return row[cell.id];
    }
  };

  return (
    <>
      <TableRow hover tabIndex={-1} key={row.id}>
        <TableCell className={classes.expandButtonCell}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {headCells.map((cell) => (
          <TableCell
            align={cellAlign(cell)}
            key={`${cell.id}-${row.id}`}
            className={clsx({
              [classes.noWrapCell]:
                cell.type === "date" || cell.type === "user",
              [classes.fullWith]:
                cell.id === "balancePayoutBankAccountConnection",
            })}
          >
            {renderCell(cell, row)}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={headCells.length + 1}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box paddingY={2}>
              <Box className={classes.userDetails}>
                <LabelledChip
                  label="Name"
                  text={row.requestedByUserConnection.displayName}
                />
                <LabelledChip
                  label="Phone"
                  text={row.requestedByUserConnection.mobilePhoneNumber}
                />
                <LabelledChip
                  label="Email"
                  text={row.requestedByUserConnection.email}
                />
              </Box>
              {detailsLoading || !details ? (
                <CircularProgress />
              ) : (
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {details.balanceItems.items.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell className={classes.noWrapCell}>
                          {dateAndTime(item.createdAt)}
                        </TableCell>
                        <TableCell className={classes.fullWith}>
                          {item.description}
                        </TableCell>
                        <TableCell className={classes.noWrapCell}>
                          {formatCurrency(item.amount)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const PayoutRequestsTable = memo<TableProps>(
  ({
    onRequestPage,
    onRequestPageSize,
    pageInfo,
    requests,
    onRequestSetCompleted,
    loading,
  }) => {
    const classes = useStyles();
    const handleChangePage = (_: any, page: number) => onRequestPage(page + 1);
    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      onRequestPageSize(parseInt(event.target.value, 10));
    };

    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead classes={classes} />
              <TableBody>
                {requests.map((row) => {
                  return (
                    <PayoutRow
                      key={row.id}
                      row={row}
                      classes={classes}
                      onRequestSetCompleted={onRequestSetCompleted}
                      loading={loading}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            rowsPerPageOptions={[10, 50, 100, 500, 1000]}
            count={pageInfo.totalCount}
            rowsPerPage={pageInfo.size}
            page={pageInfo.page - 1}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
);

export default PayoutRequestsTable;
