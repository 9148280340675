/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AppTextDocumentKind {
  about_again_team = "about_again_team",
  about_again_user_flow = "about_again_user_flow",
  about_again_user_flow_and_datapoints = "about_again_user_flow_and_datapoints",
  about_again_v2 = "about_again_v2",
  about_again_v2_title = "about_again_v2_title",
  about_approved_companies = "about_approved_companies",
  about_bonus_title = "about_bonus_title",
  about_cashback = "about_cashback",
  about_cashback_title = "about_cashback_title",
  about_community_goal = "about_community_goal",
  about_community_goal_label = "about_community_goal_label",
  about_community_goal_sublabel = "about_community_goal_sublabel",
  about_community_goal_text = "about_community_goal_text",
  about_contest = "about_contest",
  about_creating_data = "about_creating_data",
  about_datapoints = "about_datapoints",
  about_handling_data = "about_handling_data",
  about_payout = "about_payout",
  about_payout_3months_50sek = "about_payout_3months_50sek",
  about_rewards = "about_rewards",
  about_rewards_and_bonus = "about_rewards_and_bonus",
  about_rewards_title = "about_rewards_title",
  about_surveys = "about_surveys",
  about_transactions = "about_transactions",
  all_faq_articles = "all_faq_articles",
  app_greeting = "app_greeting",
  bonus_modal_description = "bonus_modal_description",
  bonus_modal_title = "bonus_modal_title",
  cashback_box_title = "cashback_box_title",
  general_terms_and_conditions = "general_terms_and_conditions",
  information_create_bank_connection = "information_create_bank_connection",
  invite_box_see_all = "invite_box_see_all",
  invite_box_title = "invite_box_title",
  invite_button_description = "invite_button_description",
  invite_button_primary = "invite_button_primary",
  invite_button_secondary = "invite_button_secondary",
  marquee_contest_main = "marquee_contest_main",
  marquee_scaffolding_main = "marquee_scaffolding_main",
  marquee_scaffolding_no_credentials = "marquee_scaffolding_no_credentials",
  mobile_phone_number_consent_check = "mobile_phone_number_consent_check",
  mobile_phone_number_input_label = "mobile_phone_number_input_label",
  mobile_phone_number_post_consent_check = "mobile_phone_number_post_consent_check",
  mobile_phone_number_pre_consent_check = "mobile_phone_number_pre_consent_check",
  onboarding_action_button_text = "onboarding_action_button_text",
  onboarding_action_description_text = "onboarding_action_description_text",
  privacy_notice = "privacy_notice",
  redeem_voucher_swipe_button = "redeem_voucher_swipe_button",
  redeemed_voucher_swipe_button = "redeemed_voucher_swipe_button",
  system_announcement = "system_announcement",
  user_invitation_message_template = "user_invitation_message_template",
  user_invitation_message_v2_template = "user_invitation_message_v2_template",
  user_invitation_message_v2_template_elig = "user_invitation_message_v2_template_elig",
}

export enum BalancePayoutStatus {
  completed = "completed",
  requested = "requested",
}

export enum CompanySubscriptionPlan {
  basic = "basic",
  premium = "premium",
}

export enum DBSortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export enum DisbursementErrorCode {
  processing_error = "processing_error",
  swish_already_paid = "swish_already_paid",
  swish_error = "swish_error",
  swish_immediate_error = "swish_immediate_error",
  swish_payout_pending = "swish_payout_pending",
}

export enum DisbursementStatus {
  created = "created",
  dispatched = "dispatched",
  error = "error",
  nullified = "nullified",
  paid = "paid",
  processed = "processed",
  processing = "processing",
}

export enum DisbursementType {
  contest_reward = "contest_reward",
  manual_payout = "manual_payout",
  onboarding = "onboarding",
  referral = "referral",
  reward_earned = "reward_earned",
  swipe_card_answer = "swipe_card_answer",
  swish_number_verification = "swish_number_verification",
}

export enum EntityDeleteState {
  deleted = "deleted",
  requested = "requested",
}

export enum FeatureFlagType {
  allow_switch_market = "allow_switch_market",
  disable_tink_credentials = "disable_tink_credentials",
  enable_enable_banking_accounts = "enable_enable_banking_accounts",
  enable_kosma_consents = "enable_kosma_consents",
  enable_nordigen_requisitions = "enable_nordigen_requisitions",
  enable_tink_credentials = "enable_tink_credentials",
  is_company_demo_app_user = "is_company_demo_app_user",
  prefer_nordigen_web_browser = "prefer_nordigen_web_browser",
  use_nordigen_account_redirect = "use_nordigen_account_redirect",
  use_nordigen_account_selection = "use_nordigen_account_selection",
}

export enum PayoutType {
  balance_bank_payout = "balance_bank_payout",
  swish_immediate_payout = "swish_immediate_payout",
}

export enum RewardConditionType {
  accountCreatedAfter = "accountCreatedAfter",
  accountMinAge = "accountMinAge",
  appStoreMinVersion = "appStoreMinVersion",
  inCampaign = "inCampaign",
  invitedAccount = "invitedAccount",
  nonInvitedAccount = "nonInvitedAccount",
  playStoreMinVersion = "playStoreMinVersion",
  rewardEnabled = "rewardEnabled",
  surveyCompleted = "surveyCompleted",
  surveyMinCount = "surveyMinCount",
  synoSurveyCompleted = "synoSurveyCompleted",
  transactionMinCount = "transactionMinCount",
  verifiedBrandsMinCount = "verifiedBrandsMinCount",
}

export enum SupportedCurrencyCode {
  NOK = "NOK",
  SEK = "SEK",
}

export enum SupportedLanguageCode {
  nb = "nb",
  sv = "sv",
}

export enum SupportedRegionCode {
  NO = "NO",
  SE = "SE",
}

export enum SurveyBatchAnswerStatus {
  completed = "completed",
  not_started = "not_started",
  started = "started",
}

export enum SystemActivityLogCategory {
  company_added = "company_added",
  company_apply_for_premium = "company_apply_for_premium",
  company_improvement_added = "company_improvement_added",
  company_improvement_removed = "company_improvement_removed",
  company_improvement_updated = "company_improvement_updated",
  company_invitation_added = "company_invitation_added",
  company_location_added = "company_location_added",
  company_location_removed = "company_location_removed",
  company_merged = "company_merged",
  company_metadata_updated = "company_metadata_updated",
  company_replied_on_idea_for_change = "company_replied_on_idea_for_change",
  company_replied_on_review = "company_replied_on_review",
  company_supporter_added = "company_supporter_added",
  company_supporter_removed = "company_supporter_removed",
  company_upload_logo = "company_upload_logo",
  company_user_signed_up = "company_user_signed_up",
  company_visibility_toggled = "company_visibility_toggled",
  idea_for_change_comment_added = "idea_for_change_comment_added",
  idea_for_change_created = "idea_for_change_created",
  idea_for_change_endorsement_added = "idea_for_change_endorsement_added",
  review_comment_added = "review_comment_added",
  review_endorsement_added = "review_endorsement_added",
  user_account_delete_confirmed = "user_account_delete_confirmed",
  user_account_delete_requested = "user_account_delete_requested",
  user_account_undeleted = "user_account_undeleted",
  user_attributed_score = "user_attributed_score",
  user_invitation_added = "user_invitation_added",
  user_invitation_used = "user_invitation_used",
  user_signed_up = "user_signed_up",
  user_synced_first_transaction = "user_synced_first_transaction",
  user_updated_mobile_phone_number = "user_updated_mobile_phone_number",
}

export enum TinkCredentialOperationCategory {
  authenticate_cancelled = "authenticate_cancelled",
  authenticate_completed = "authenticate_completed",
  authenticate_failed = "authenticate_failed",
  authenticate_started = "authenticate_started",
  create_cancelled = "create_cancelled",
  create_completed = "create_completed",
  create_failed = "create_failed",
  create_started = "create_started",
  delete_completed = "delete_completed",
  delete_failed = "delete_failed",
  delete_started = "delete_started",
  last_known_status_changed = "last_known_status_changed",
  unknown = "unknown",
}

export enum TinkCredentialStatus {
  AUTHENTICATING = "AUTHENTICATING",
  AUTHENTICATION_ERROR = "AUTHENTICATION_ERROR",
  AWAITING_MOBILE_BANKID_AUTHENTICATION = "AWAITING_MOBILE_BANKID_AUTHENTICATION",
  AWAITING_SUPPLEMENTAL_INFORMATION = "AWAITING_SUPPLEMENTAL_INFORMATION",
  AWAITING_THIRD_PARTY_APP_AUTHENTICATION = "AWAITING_THIRD_PARTY_APP_AUTHENTICATION",
  CREATED = "CREATED",
  DELETED = "DELETED",
  PERMANENT_ERROR = "PERMANENT_ERROR",
  SESSION_EXPIRED = "SESSION_EXPIRED",
  TEMPORARY_ERROR = "TEMPORARY_ERROR",
  UPDATED = "UPDATED",
  UPDATING = "UPDATING",
}

export enum TransactionSyncSummaryDateField {
  createdAt = "createdAt",
  transactionTimestamp = "transactionTimestamp",
}

export enum UserGender {
  female = "female",
  male = "male",
}

export interface AddAppTextDocumentInput {
  languageCode: SupportedLanguageCode;
  kind: AppTextDocumentKind;
  markdown: string;
}

export interface AddAppTextDocumentWithContentInput {
  kind: AppTextDocumentKind;
  content: AppTextDocumentContentInput[];
}

export interface AddCompanyImprovementInput {
  improvementText: string;
}

export interface AddCompanyInput {
  companyName: string;
}

export interface AddCompanyInvitationInput {
  companyId: string;
}

export interface AddCompanyLocationInput {
  companyAddress: string;
  companyPhoneNumber: string;
}

export interface AddCompanySupporterInput {
  email: string;
}

export interface AddDisbursementOnboardingInput {
  userId: string;
  overrides?: DisbursementOverridesInput | null;
}

export interface AddDisbursementUserReferralInput {
  invitationId: string;
  overrides?: DisbursementOverridesInput | null;
}

export interface AllCompaniesInput {
  companyName?: string | null;
  isVisible?: boolean | null;
  hasLogo?: boolean | null;
  isVerified?: boolean | null;
  paging?: InputListPaging | null;
  sort?: InputListSort | null;
}

export interface AllDisbursementsSearchOptions {
  filter?: InputDisbursementsFilter | null;
  sort?: InputListSort | null;
  paging?: InputListPaging | null;
}

export interface AllSystemUsersSearchOptions {
  filter?: InputSystemUsersFilter | null;
  sort?: InputListSort | null;
  paging?: InputListPaging | null;
}

export interface AppTextDocumentContentInput {
  languageCode: SupportedLanguageCode;
  markdown: string;
}

export interface AssignMerchantsInput {
  companyId: string;
  merchants: string[];
}

export interface BalancePayoutBankAccountRequestsFilter {
  status?: BalancePayoutStatus | null;
}

export interface DisbursementOverridesInput {
  message?: string | null;
  amountInCents?: number | null;
}

export interface InputDisbursementsFilter {
  status?: DisbursementStatus[] | null;
  type?: DisbursementType[] | null;
  payoutType?: PayoutType[] | null;
  userId?: string[] | null;
  message?: string | null;
}

export interface InputListMerchantsFilter {
  unassignedOnly?: boolean | null;
}

export interface InputListPaging {
  size?: number | null;
  page?: number | null;
}

export interface InputListSort {
  sort: DBSortOrder;
  sortField: string;
}

export interface InputSystemActivityLogFilter {
  body?: string | null;
  categories?: SystemActivityLogCategory[] | null;
  primaryEntityId?: string | null;
  secondaryEntityId?: string | null;
  marketId?: number | null;
}

export interface InputSystemUsersFilter {
  search?: string | null;
  isEnabled?: boolean | null;
  deleteStatus?: EntityDeleteState[] | null;
  marketId?: number | null;
}

export interface ListMerchantsSearchOptions {
  filter?: InputListMerchantsFilter | null;
}

export interface LogoMediaUploadInput {
  contentLength: number;
  contentMimeType: string;
}

export interface MergeCompaniesInput {
  targetCompany: string;
  sourceCompanies: string[];
}

export interface PremiumApplicationInput {
  name: string;
  email: string;
  phone: string;
  applicationText: string;
}

export interface ReindexCompanyMerchantsInput {
  companyId: string;
}

export interface RetryDisbursementInput {
  disbursementId: string;
  message?: string | null;
  amountInCents?: number | null;
}

export interface SearchTinkCredentialOperationLogFilter {
  userId?: string | null;
  credentialId?: string | null;
  categories?: TinkCredentialOperationCategory[] | null;
}

export interface SearchTinkCredentialOperationLogInput {
  filter?: SearchTinkCredentialOperationLogFilter | null;
  paging?: InputListPaging | null;
  sort?: InputListSort | null;
}

export interface SetCompaniesVisibleInput {
  visible: boolean;
  companyIds: string[];
}

export interface SystemActivityLogSearchOptions {
  filter?: InputSystemActivityLogFilter | null;
  sort?: InputListSort | null;
  paging?: InputListPaging | null;
}

export interface UpdateAppTextDocumentInput {
  appDocumentId: string;
  languageCode: SupportedLanguageCode;
  markdown: string;
}

export interface UpdateCompanyImprovementInput {
  companyImprovementId: string;
  completed?: boolean | null;
  improvementText?: string | null;
}

export interface UpdateCompanyInput {
  companyName: string;
  description?: string | null;
}

export interface UserTransactionSyncSummaryOptions {
  userId: string;
  dateField: TransactionSyncSummaryDateField;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
