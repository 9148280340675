import React, { useCallback, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import {
  InputListSort,
  DBSortOrder,
  DisbursementType,
  DisbursementStatus,
} from "../../../graphql/types/global";
import {
  AllDisbursements,
  AllDisbursementsVariables,
} from "../../../graphql/types/AllDisbursements";
import { ALL_DISBURSEMENTS } from "../../../graphql/queries.gql";
import { Disbursement } from "../../../graphql/helperTypes";
import DisbursementsFilter, { defaultStatuses } from "./DisbursementsFilter";
import DisbursementsTable from "./DisbursementsTable";
import DisbursementStatusDialog from "../Shared/DisbursementStatusDialog";
import {
  DisbursementStatusDialogData,
  DisbursementStatusDialogState,
} from "../Shared/DisbursementStatusDialog/types";
import PushUserNotificationDialog from "../Shared/PushUserNotificationDialog";
import {
  PushUserNotificationDialogData,
  PushUserNotificationDialogState,
} from "../Shared/PushUserNotificationDialog/types";

const DisbursementsPage: React.FC = () => {
  const [disbursementStatusModalState, setDisbursementStatusModalState] =
    useState<DisbursementStatusDialogState>({ isOpen: false });
  const [pushUserNotificationModalState, setPushUserNotificationModalState] =
    useState<PushUserNotificationDialogState>({ isOpen: false });
  const [message, setMessage] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [statuses, setStatuses] =
    useState<DisbursementStatus[]>(defaultStatuses);
  const [types, setTypes] = useState<DisbursementType[]>([]);
  const [sorting, setSorting] = useState<InputListSort>({
    sortField: "createdAt",
    sort: DBSortOrder.DESC,
  });
  const [debouncedMessage, setDebouncedMessage] = useState(message);

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedMessage(message), 500);
    return () => clearTimeout(handler);
  }, [message]);

  const { data, refetch, loading } = useQuery<
    AllDisbursements,
    AllDisbursementsVariables
  >(ALL_DISBURSEMENTS, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        filter: { message: debouncedMessage, type: types, status: statuses },
        paging: { page, size: pageSize },
        sort: sorting,
      },
    },
  });

  const handleRefetch = useCallback(() => refetch(), [refetch]);

  const handleRequestSort = useCallback(
    (property: keyof Disbursement) => {
      const supportedProperties: (keyof Disbursement)[] = ["createdAt"];
      if (!supportedProperties.includes(property)) {
        return;
      }
      const isAsc =
        sorting.sortField === property && sorting.sort === DBSortOrder.ASC;
      setSorting({
        sort: isAsc ? DBSortOrder.DESC : DBSortOrder.ASC,
        sortField: property,
      });
    },
    [sorting, setSorting]
  );

  const handleRequestPageSize = useCallback(
    (pageSize: number) => {
      setPageSize(pageSize);
      setPage(1);
    },
    [setPage, setPageSize]
  );

  const handleRequestPage = useCallback(
    (page: number) => setPage(page),
    [setPage]
  );

  const handleOpenDisbursementStatusModal = useCallback(
    (data: DisbursementStatusDialogData) =>
      setDisbursementStatusModalState({ isOpen: true, data }),
    [setDisbursementStatusModalState]
  );

  const handleCloseDisbursementStatusModal = useCallback(() => {
    setDisbursementStatusModalState({ isOpen: false });
    handleRefetch();
  }, [setDisbursementStatusModalState, handleRefetch]);

  const handleClosePushUserNotificationModal = useCallback(() => {
    setPushUserNotificationModalState({ isOpen: false });
  }, [setPushUserNotificationModalState]);

  const handleOpenUserNotificationModal = useCallback(
    (data: PushUserNotificationDialogData) =>
      setPushUserNotificationModalState({ isOpen: true, data }),
    [setPushUserNotificationModalState]
  );

  return (
    <div>
      <DisbursementsFilter
        message={message}
        types={types}
        statuses={statuses}
        onRequestSetMessage={setMessage}
        onRequestSetTypes={setTypes}
        onRequestSetStatuses={setStatuses}
        onRequestRefetch={handleRefetch}
        loading={loading}
      />
      {data ? (
        <DisbursementsTable
          disbursements={data.allDisbursements.disbursements}
          pageInfo={data.allDisbursements.pageInfo}
          onRequestRefetch={handleRefetch}
          onRequestSort={handleRequestSort}
          onRequestPage={handleRequestPage}
          onRequestPageSize={handleRequestPageSize}
          onRequestDisbursementStatusDialog={handleOpenDisbursementStatusModal}
          sorting={sorting}
        />
      ) : null}

      <DisbursementStatusDialog
        modalState={disbursementStatusModalState}
        onRequestClose={handleCloseDisbursementStatusModal}
        onRequestUserNotificationDialog={handleOpenUserNotificationModal}
      />
      <PushUserNotificationDialog
        modalState={pushUserNotificationModalState}
        onRequestClose={handleClosePushUserNotificationModal}
      />
    </div>
  );
};

export default DisbursementsPage;
