import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import AuthenticatedRoute, {
  AuthenticatedStandaloneRoute,
} from "./routes/AuthenticatedRoute";
import AssignCompany from "./routes/Admin/Assign/AssignCompany";
import Logo from "./routes/Settings/Logo";
import WithSettingsDrawer from "./components/WithSettingsDrawer";
import Locations from "./routes/Settings/Locations";
import Improvements from "./routes/Settings/Improvements";
import Supporters from "./routes/Settings/Supporters";
import Misc from "./routes/Settings/Misc";
import WithAdminDrawer from "./routes/Admin/WithAdminDrawer";
import SystemActitvityLogPage from "./routes/Admin/Activity/SystemActivityLogPage";
import SystemUsersPage from "./routes/Admin/Users/SystemUsersPage";
import useGoogleAnalytics from "./utils/useGoogleAnalytics";
import DocumentsPage from "./routes/Admin/Documents/DocumentsPage";
import CreateDocument from "./routes/Admin/Documents/AddDocument";
import EditDocument from "./routes/Admin/Documents/EditDocument";
import DisbursementsPage from "./routes/Admin/Disbursements/DisbursementsPage";
import UserDeletesPage from "./routes/Admin/Users/Deletes/UserDeletesPage";
import SurveyDemoPage from "./routes/Survey/SurveyDemoPage";
import DemosPage from "./routes/Admin/Demos/DemosPage";
import DemoDetailsPage from "./routes/Admin/DemoDetails/DemoDetailsPage";
import CampaignsPage from "./routes/Admin/Campaigns/CampaignsPage";
import CampaignDetailsPage from "./routes/Admin/CampaignDetails/CampaignDetailsPage";
import CampaignInstanceDetailsPage from "./routes/Admin/CampaignInstanceDetails/CampaignInstanceDetailsPage";
import CompanyDetailsPage from "./routes/Admin/Company/CompanyDetails";
import { AuthCallback } from "./routes/Auth/Callback";
import { SignInPage } from "./routes/StartPage/SignIn";
import { LoggedOut } from "./routes/Auth/LoggedOut";
import { LogOut } from "./routes/Auth/LogOut";
import UsersLookupPage from "./routes/Admin/Users/UsersLookup";
import { BatchReportsPage } from "./routes/Admin/BatchReport/BatchReportsPage";
import { BatchReportPage } from "./routes/Admin/BatchReport/BatchReportPage";
import PayoutRequestsPage from "./routes/Admin/PayoutRequests/PayoutRequestsPage";

function App() {
  useGoogleAnalytics();
  return (
    <Switch>
      <Route path="/auth/callback">
        <AuthCallback />
      </Route>
      <Route path="/auth/logout">
        <LogOut />
      </Route>
      <Route path="/auth/logged-out">
        <LoggedOut />
      </Route>
      <AuthenticatedStandaloneRoute path="/survey">
        <SurveyDemoPage />
      </AuthenticatedStandaloneRoute>
      <Redirect exact from="/admin" to="/admin/activity/1" />
      <Redirect exact from="/admin/activity" to="/admin/activity/1" />
      <AuthenticatedRoute path="/admin/activity/:marketId" container={false}>
        <WithAdminDrawer>
          <SystemActitvityLogPage />
        </WithAdminDrawer>
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/admin/assign" container={false}>
        <WithAdminDrawer>
          <AssignCompany />
        </WithAdminDrawer>
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/admin/company/:id" container={false}>
        <WithAdminDrawer>
          <CompanyDetailsPage />
        </WithAdminDrawer>
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/admin/users/deletes" container={false}>
        <WithAdminDrawer>
          <UserDeletesPage />
        </WithAdminDrawer>
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/admin/users/lookup" container={false}>
        <WithAdminDrawer>
          <UsersLookupPage />
        </WithAdminDrawer>
      </AuthenticatedRoute>
      <Redirect exact from="/admin/users" to="/admin/users/1" />
      <AuthenticatedRoute path="/admin/users/:marketId" container={false}>
        <WithAdminDrawer>
          <SystemUsersPage />
        </WithAdminDrawer>
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/admin/documents/edit/:id" container={false}>
        <WithAdminDrawer>
          <EditDocument />
        </WithAdminDrawer>
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/admin/documents/add" container={false}>
        <WithAdminDrawer>
          <CreateDocument />
        </WithAdminDrawer>
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/admin/documents" container={false}>
        <WithAdminDrawer>
          <DocumentsPage />
        </WithAdminDrawer>
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/admin/disbursements" container={false}>
        <WithAdminDrawer>
          <DisbursementsPage />
        </WithAdminDrawer>
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/admin/payouts" container={false}>
        <WithAdminDrawer>
          <PayoutRequestsPage />
        </WithAdminDrawer>
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/admin/demos/:id" container={false}>
        <WithAdminDrawer>
          <DemoDetailsPage />
        </WithAdminDrawer>
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/admin/demos" container={false}>
        <WithAdminDrawer>
          <DemosPage />
        </WithAdminDrawer>
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/admin/campaigns" container={false}>
        <WithAdminDrawer>
          <CampaignsPage />
        </WithAdminDrawer>
      </AuthenticatedRoute>
      <AuthenticatedRoute
        path="/admin/campaign/:campaignId/instance/:campaignInstanceId"
        container={false}
        exact
      >
        <WithAdminDrawer>
          <CampaignInstanceDetailsPage />
        </WithAdminDrawer>
      </AuthenticatedRoute>
      <AuthenticatedRoute
        path="/admin/campaign/:campaignId"
        container={false}
        exact
      >
        <WithAdminDrawer>
          <CampaignDetailsPage />
        </WithAdminDrawer>
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/admin/batchreports" container={false} exact>
        <WithAdminDrawer>
          <BatchReportsPage />
        </WithAdminDrawer>
      </AuthenticatedRoute>
      <AuthenticatedRoute
        path="/admin/batchreport/:batchNumber"
        container={false}
        exact
      >
        <WithAdminDrawer>
          <BatchReportPage />
        </WithAdminDrawer>
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/settings" exact={true}>
        <WithSettingsDrawer>
          <Logo />
        </WithSettingsDrawer>
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/settings/logo" exact={true}>
        <WithSettingsDrawer>
          <Logo />
        </WithSettingsDrawer>
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/settings/locations" exact={true}>
        <WithSettingsDrawer>
          <Locations />
        </WithSettingsDrawer>
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/settings/improvements" exact={true}>
        <WithSettingsDrawer>
          <Improvements />
        </WithSettingsDrawer>
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/settings/supporters" exact={true}>
        <WithSettingsDrawer>
          <Supporters />
        </WithSettingsDrawer>
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/settings/misc" exact={true}>
        <WithSettingsDrawer>
          <Misc />
        </WithSettingsDrawer>
      </AuthenticatedRoute>
      <Route path="/" exact={true}>
        <SignInPage />
      </Route>
    </Switch>
  );
}

export default App;
