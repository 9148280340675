import { gql } from "@apollo/client";

const AmountFragment = gql`
  fragment AmountFragment on AmountDefinition {
    cents
    currency
  }
`;

const AgainUserMinimalFragment = gql`
  fragment AgainUserMinimalFragment on AgainUser {
    id: userId
    profileConnection {
      slug
      displayName
      avatarUrl
    }
  }
`;

const PageInfoFullFragment = gql`
  fragment PageInfoFullFragment on PageInfo {
    totalCount
    count
    page
    size
  }
`;

export const PREPARE_LOGO_MEDIA_UPLOAD = gql`
  mutation PrepareLogoMediaUpload(
    $input: LogoMediaUploadInput!
    $companyId: ID
  ) {
    prepareLogoMediaUpload(input: $input, companyId: $companyId) {
      signedUrl
      uploadId
    }
  }
`;

export const COMPLETE_MEDIA_UPLOAD = gql`
  mutation CompleteMediaUpload($uploadId: ID!) {
    completeMediaUpload(uploadId: $uploadId) {
      uploadId
    }
  }
`;

export const ASSIGN_MERCHANTS = gql`
  mutation AssignMerchants($input: AssignMerchantsInput!) {
    assignMerchants(input: $input)
  }
`;

const companyDisplayFragment = gql`
  fragment companyDisplayFragment on Company {
    id: companyId
    companyName
    logoThumbnailUrl
    logoUrl
  }
`;

const merchantDisplayFragment = gql`
  fragment merchantDisplayFragment on Merchant {
    id: merchantId
    merchantName
  }
`;

const meFragment = gql`
  fragment meFragment on MeUserInfo {
    id: userId
    companyId
    companyConnection {
      ...companyDisplayFragment
      description
    }
  }
  ${companyDisplayFragment}
`;

export const ME = gql`
  query Me {
    me {
      ...meFragment
    }
  }
  ${meFragment}
`;

export const COMPANY_DETAILS = gql`
  query CompanyDetails(
    $companyId: ID!
    $paging: InputListPaging!
    $sort: InputListSort
  ) {
    company(companyId: $companyId) {
      ...companyDisplayFragment
      merchantConnection(paging: $paging, sort: $sort) {
        merchants {
          ...merchantDisplayFragment
        }
        pageInfo {
          ...PageInfoFullFragment
        }
      }
    }
  }
  ${companyDisplayFragment}
  ${merchantDisplayFragment}
  ${PageInfoFullFragment}
`;

export const ALL_COMPANIES = gql`
  query AllCompanies($input: AllCompaniesInput) {
    allCompanies(input: $input) {
      companies {
        ...companyDisplayFragment
        isVisible
        isVerified
        statisticsConnection {
          consumerCount
          transactionCount
        }
      }
      pageInfo {
        ...PageInfoFullFragment
      }
    }
  }
  ${companyDisplayFragment}
  ${PageInfoFullFragment}
`;

export const ALL_MERCHANTS = gql`
  query AllMerchants($searchOptions: ListMerchantsSearchOptions) {
    allMerchants(searchOptions: $searchOptions) {
      merchants {
        merchantName
        merchantId
      }
    }
  }
`;

export const ALL_COMPANY_LOCATIONS = gql`
  query AllCompanyLocations($companyId: ID!) {
    allCompanyLocations(companyId: $companyId) {
      locations {
        companyLocationId
        companyAddress
        companyPhoneNumber
      }
    }
  }
`;

export const ALL_COMPANY_SUPPORTERS = gql`
  query AllCompanySupporters($companyId: ID!) {
    allCompanySupporters(companyId: $companyId) {
      supporters {
        companySupporterId
        email
      }
    }
  }
`;

export const ALL_COMPANY_IMPROVEMENTS = gql`
  query AllCompanyImprovements($companyId: ID!) {
    allCompanyImprovements(companyId: $companyId) {
      improvements {
        companyImprovementId
        companyId
        improvementText
        completed
        createdAt
      }
    }
  }
`;

export const MERGE_COMPANIES = gql`
  mutation MergeCompanies($input: MergeCompaniesInput!) {
    mergeCompanies(input: $input) {
      companyId
    }
  }
`;

export const ADD_COMPANY = gql`
  mutation AddCompany($input: AddCompanyInput!) {
    addCompany(input: $input) {
      companyId
    }
  }
`;

export const SET_COMPANIES_VISIBLE = gql`
  mutation SetCompaniesVisible($input: SetCompaniesVisibleInput!) {
    setCompaniesVisible(input: $input)
  }
`;

const DisbursementMinimalFragment = gql`
  fragment DisbursementMinimalFragment on Disbursement {
    id: disbursementId
    status
    type
    userId
  }
`;

export const ADD_DISBURSEMENT_ONBOARDING = gql`
  mutation addDisbursementOnboarding($input: AddDisbursementOnboardingInput!) {
    addDisbursementOnboarding(input: $input) {
      ...DisbursementMinimalFragment
    }
  }
  ${DisbursementMinimalFragment}
`;

export const ADD_DISBURSEMENT_USER_REFERRAL = gql`
  mutation addDisbursementUserReferral(
    $input: AddDisbursementUserReferralInput!
  ) {
    addDisbursementUserReferral(input: $input) {
      ...DisbursementMinimalFragment
    }
  }
  ${DisbursementMinimalFragment}
`;

export const ALL_SYSTEM_ACTIVITY_LOGS = gql`
  query AllSystemActivityLogs($input: SystemActivityLogSearchOptions) {
    allSystemActivityLogs(input: $input) {
      systemActivityLogs {
        systemActivityLogId
        primaryEntityId
        createdAt
        category
        body
        disbursementPossibleType
        disbursementConnection {
          ...DisbursementMinimalFragment
        }
      }
      pageInfo {
        ...PageInfoFullFragment
      }
    }
  }
  ${DisbursementMinimalFragment}
  ${PageInfoFullFragment}
`;

const AppTextDocumentContentFragment = gql`
  fragment AppTextDocumentContentFragment on AppTextDocumentContent {
    languageCode
    markdown
  }
`;

export const ALL_APP_TEXT_DOCUMENTS = gql`
  query allAppTextDocuments($kind: AppTextDocumentKind) {
    allAppTextDocuments(kind: $kind) {
      documents {
        id: appDocumentId
        kind
        createdAt
        updatedAt
        createdByConnection {
          displayName
        }
        updatedByConnection {
          displayName
        }
        campaignConnection {
          id: campaignId
          description
          companyConnection {
            ...companyDisplayFragment
          }
        }
        allContentConnection {
          ...AppTextDocumentContentFragment
        }
      }
    }
  }
  ${companyDisplayFragment}
  ${AppTextDocumentContentFragment}
`;

export const APP_TEXT_DOCUMENT_BY_ID = gql`
  query appTextDocumentById($id: ID!) {
    appTextDocumentById(id: $id) {
      document {
        id: appDocumentId
        kind
        createdAt
        updatedAt
        isArchived
        createdByConnection {
          displayName
        }
        updatedByConnection {
          displayName
        }
        campaignConnection {
          id: campaignId
          description
          companyConnection {
            ...companyDisplayFragment
          }
        }
        allContentConnection {
          ...AppTextDocumentContentFragment
        }
      }
    }
  }
  ${companyDisplayFragment}
  ${AppTextDocumentContentFragment}
`;

const systemUserDetailsFragment = gql`
  fragment systemUserDetailsFragment on SystemUser {
    id: userId
    displayName
    email
    mobilePhoneNumber
    createdAt
    isEnabled
    deleteStatus
    onboardingDisbursementConnection {
      ...DisbursementMinimalFragment
    }
    userInvitationConnection {
      id: userInvitationId
      createdBy
      disbursementConnection {
        ...DisbursementMinimalFragment
      }
    }
    mappedCampaignInstancesConnection {
      instances {
        campaignInstanceConnection {
          id: campaignInstanceId
          slug
        }
      }
    }
    verifiedBrandsCount
    bankConnectionCount
  }
  ${DisbursementMinimalFragment}
`;

const systemUserListFragment = gql`
  fragment systemUserListFragment on SystemUser {
    id: userId
    displayName
    email
    mobilePhoneNumber
    createdAt
    isEnabled
    deleteStatus
    onboardingDisbursementConnection {
      ...DisbursementMinimalFragment
    }
    userInvitationConnection {
      id: userInvitationId
      createdBySystemConnection {
        id: userId
        displayName
        email
      }
    }
  }
  ${DisbursementMinimalFragment}
`;

const systemUserDeletesFragment = gql`
  fragment systemUserDeletesFragment on SystemUser {
    fullName
    personalNumber
    createdAt
    email
    mobilePhoneNumber
    deletedAt
    deleteStatus
    id: userId
    cachedTinkCredentialsConnection {
      tinkUserId
    }
  }
`;

export const ALL_SYSTEM_USERS = gql`
  query AllSystemUsers($input: AllSystemUsersSearchOptions) {
    allSystemUsers(input: $input) {
      users {
        ...systemUserDetailsFragment
      }
      pageInfo {
        ...PageInfoFullFragment
      }
    }
  }
  ${systemUserDetailsFragment}
  ${PageInfoFullFragment}
`;

export const SYSTEM_USERS_DELETES = gql`
  query SystemUsersDeletes($input: AllSystemUsersSearchOptions) {
    allSystemUsers(input: $input) {
      users {
        ...systemUserDeletesFragment
      }
      pageInfo {
        ...PageInfoFullFragment
      }
    }
  }
  ${systemUserDeletesFragment}
  ${PageInfoFullFragment}
`;

export const SYSTEM_USER = gql`
  query systemUser($userId: ID!) {
    systemUser(userId: $userId) {
      ...systemUserDetailsFragment
    }
  }
  ${systemUserDetailsFragment}
`;

export const USER_LOOKUP = gql`
  query userLookup($userId: ID!) {
    systemUser(userId: $userId) {
      id: userId
      displayName
      fullName
      email
      mobilePhoneNumber
      personalNumber
    }
  }
`;

export const COMPANY_INVITATION_INFO = gql`
  query CompanyInvitationInfo($invitationCode: ID!) {
    companyInvitationInfo(invitationCode: $invitationCode) {
      companyConnection {
        id: companyId
        companyName
        logoUrl
      }
      invitationConnection {
        validUntil
      }
    }
  }
`;

export const TINK_CREDENTIAL_LOG_OPERATIONS = gql`
  query TinkCredentialLogOperations(
    $input: SearchTinkCredentialOperationLogInput
  ) {
    allTinkCredentialOperationLogs(input: $input) {
      logs {
        operationId
        sessionId
        category
        createdAt
        message
        credentialId
        userId
        credentialConnection {
          providerName
        }
        userConnection {
          ...AgainUserMinimalFragment
        }
      }
      pageInfo {
        ...PageInfoFullFragment
      }
    }
  }
  ${AgainUserMinimalFragment}
  ${PageInfoFullFragment}
`;

export const USER_TRANSACTION_SYNC_SUMMARY = gql`
  query UserTransactionSyncSummary($input: UserTransactionSyncSummaryOptions!) {
    userTransactionSyncSummary(input: $input) {
      values {
        period
        periodLabel
        earliestTransaction
        latestTransaction
        transactionCount
        averageGap {
          ...DbIntervalDateHoursMinutes
        }
        longestGap {
          ...DbIntervalDateHoursMinutes
        }
      }
    }
  }
  fragment DbIntervalDateHoursMinutes on DbInterval {
    years
    months
    days
    hours
    minutes
  }
`;

export const ME_COMPANY_SUBSCRIPTION = gql`
  query MeCompanySubscription {
    me {
      id: userId
      companyId
      companyConnection {
        id: companyId
        subscriptionConnection {
          subscriptionPlan
        }
      }
    }
  }
`;

export const SET_USER_SWISH_PHONE_NUMBER = gql`
  mutation setUserSwishPhoneNumber($swishPhoneNumber: String!, $userId: ID!) {
    setUserSwishPhoneNumber(
      swishPhoneNumber: $swishPhoneNumber
      userId: $userId
    ) {
      id: userId
      mobilePhoneNumber
    }
  }
`;

export const ADD_COMPANY_LOCATION = gql`
  mutation AddCompanyLocation($input: AddCompanyLocationInput!) {
    addCompanyLocation(input: $input) {
      companyLocationId
    }
  }
`;

export const REMOVE_COMPANY_LOCATION = gql`
  mutation RemoveCompanyLocation($id: ID!) {
    removeCompanyLocation(companyLocationId: $id)
  }
`;

export const ADD_COMPANY_SUPPORTER = gql`
  mutation AddCompanySupporter($input: AddCompanySupporterInput!) {
    addCompanySupporter(input: $input) {
      companySupporterId
    }
  }
`;

export const REMOVE_COMPANY_SUPPORTER = gql`
  mutation RemoveCompanySupporter($id: ID!) {
    removeCompanySupporter(companySupporterId: $id)
  }
`;

export const COMPANY_ITEM_DISPLAY = gql`
  query CompanyItemDisplay($companyId: ID!) {
    company(companyId: $companyId) {
      ...companyDisplayFragment
    }
  }
  ${companyDisplayFragment}
`;

export const ADD_COMPANY_INVITATION = gql`
  mutation AddCompanyInvitation($input: AddCompanyInvitationInput!) {
    addCompanyInvitation(input: $input) {
      companyInvitationId
    }
  }
`;

export const APPLY_FOR_PREMIUM = gql`
  mutation ApplyForPremium($input: PremiumApplicationInput!) {
    applyForPremium(input: $input)
  }
`;

export const UPDATE_COMPANY_IMPROVEMENT = gql`
  mutation UpdateCompanyImprovement($input: UpdateCompanyImprovementInput!) {
    updateCompanyImprovement(input: $input) {
      companyImprovementId
    }
  }
`;

export const ADD_COMPANY_IMPROVEMENT = gql`
  mutation AddCompanyImprovement($input: AddCompanyImprovementInput!) {
    addCompanyImprovement(input: $input) {
      companyImprovementId
    }
  }
`;

export const REMOVE_COMPANY_IMPROVEMENT = gql`
  mutation RemoveCompanyImprovement($id: ID!) {
    removeCompanyImprovement(companyImprovementId: $id)
  }
`;

export const UPDATE_COMPANY = gql`
  mutation UpdateCompany($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      companyId
    }
  }
`;

export const ADD_APP_TEXT_DOCUMENT = gql`
  mutation AddAppTextDocument($input: AddAppTextDocumentInput!) {
    addAppTextDocument(input: $input) {
      document {
        id: appDocumentId
      }
    }
  }
`;

export const ADD_APP_TEXT_DOCUMENT_WITH_CONTENT = gql`
  mutation AddAppTextDocumentWithContent(
    $input: AddAppTextDocumentWithContentInput!
  ) {
    addAppTextDocumentWithContent(input: $input) {
      document {
        id: appDocumentId
      }
    }
  }
`;

export const UPDATE_APP_TEXT_DOCUMENT = gql`
  mutation UpdateAppTextDocument($input: UpdateAppTextDocumentInput!) {
    updateAppTextDocument(input: $input) {
      document {
        id: appDocumentId
        kind
        updatedAt
        updatedByConnection {
          displayName
        }
        allContentConnection {
          ...AppTextDocumentContentFragment
        }
      }
    }
  }
  ${AppTextDocumentContentFragment}
`;

const SwishPayoutDetailsFragment = gql`
  fragment SwishPayoutDetailsFragment on SwishPayout {
    id: swishPayoutId
    createdAt
    payoutStatus
    paidAt
    message
    amount {
      ...AmountFragment
    }
    userConnection {
      ...AgainUserMinimalFragment
    }
    swishPayoutLogsConnection {
      id: swishPayoutLogId
      createdAt
      responseCode
      errorCode
      errorMessage
      payoutStatus
      additionalInformation
    }
  }
  ${AmountFragment}
`;

const UserInvitationDetailFragment = gql`
  fragment UserInvitationDetailFragment on UserInvitation {
    id: userInvitationId
    createdAt
    usedAt
    firstTransactionCreatedAt
    createdByConnection {
      ...AgainUserMinimalFragment
    }
    usedByConnection {
      ...AgainUserMinimalFragment
    }
  }
`;

export const USER_INVITATION = gql`
  query userInvitation($userInvitationId: ID!) {
    userInvitation(userInvitationId: $userInvitationId) {
      ...UserInvitationDetailFragment
    }
  }
  ${UserInvitationDetailFragment}
  ${AgainUserMinimalFragment}
`;

export const FETCH_SWISH_PAYOUT = gql`
  mutation fetchSwishPayout($swishPayoutId: ID!) {
    fetchSwishPayout(swishPayoutId: $swishPayoutId) {
      ...SwishPayoutDetailsFragment
    }
  }
  ${AgainUserMinimalFragment}
  ${SwishPayoutDetailsFragment}
`;

export const PUSH_SYSTEM_ANNOUNCEMENT_TO_USER = gql`
  mutation pushSystemAnnouncementToUser($userId: ID!, $message: String!) {
    pushSystemAnnouncementToUser(userId: $userId, message: $message) {
      id: userNotificationId
    }
  }
`;

export const PUSH_SYSTEM_ANNOUNCEMENT_TO_USER_INVITATION = gql`
  mutation pushSystemAnnouncementToUserInvitaionParts(
    $invitationId: ID!
    $messageToCreatedBy: String!
    $messageToUsedBy: String!
  ) {
    pushSystemAnnouncementToUserInvitaionParts(
      invitationId: $invitationId
      messageToCreatedBy: $messageToCreatedBy
      messageToUsedBy: $messageToUsedBy
    ) {
      id: userNotificationId
    }
  }
`;

export const ALL_DISBURSEMENTS = gql`
  query AllDisbursements($input: AllDisbursementsSearchOptions) {
    allDisbursements(input: $input) {
      disbursements {
        id: disbursementId
        createdAt
        type
        status
        paidOutAt
        message
        amount {
          ...AmountFragment
        }
        userId
        userConnection {
          ...AgainUserMinimalFragment
        }
      }
      pageInfo {
        ...PageInfoFullFragment
      }
    }
  }
  ${AmountFragment}
  ${AgainUserMinimalFragment}
  ${PageInfoFullFragment}
`;

const DisbursementDetailsFragment = gql`
  fragment DisbursementDetailsFragment on Disbursement {
    id: disbursementId
    createdAt
    updatedAt
    type
    status
    paidOutAt
    message
    amount {
      ...AmountFragment
    }
    userConnection {
      ...AgainUserMinimalFragment
    }
    swishPayoutConnection {
      ...SwishPayoutDetailsFragment
    }
    disbursementLogsConnection {
      id: disbursementLogId
      createdAt
      disbursementStatus
      message
      errorCode
    }
  }
  ${AmountFragment}
`;

export const DISBURSEMENT_DETAILS = gql`
  query DisbursementDetails($disbursementId: ID!) {
    disbursement(disbursementId: $disbursementId) {
      ...DisbursementDetailsFragment
    }
  }
  ${DisbursementDetailsFragment}
  ${AgainUserMinimalFragment}
  ${SwishPayoutDetailsFragment}
`;

export const RETRY_DISBURSEMENT = gql`
  mutation retryDisbursement($input: RetryDisbursementInput!) {
    retryDisbursement(input: $input) {
      id: disbursementId
      status
    }
  }
`;

export const CONFIRM_DELETE_ACCOUNT = gql`
  mutation confirmDeleteAccount($userId: ID!) {
    confirmDeleteAccount(userId: $userId) {
      deleteStatus
    }
  }
`;

export const ASSIGN_ME_TO_COMPANY = gql`
  mutation assignMeToCompany($companyId: ID!) {
    assignMeToCompany(companyId: $companyId) {
      me {
        ...meFragment
      }
    }
  }
  ${meFragment}
`;

export const REINDEX_COMPANY_MERCHANTS = gql`
  mutation reIndexCompanyMerchants($input: ReindexCompanyMerchantsInput!) {
    reIndexCompanyMerchants(input: $input)
  }
`;

export const ALL_COMPANY_DEMOS = gql`
  query allCompanyDemos {
    allCompanyDemos {
      demos {
        id: companyDemoId
        createdAt
        companyConnection {
          ...companyDisplayFragment
        }
        mappedUsersConnection {
          pageInfo {
            totalCount
          }
        }
      }
      pageInfo {
        ...PageInfoFullFragment
      }
    }
  }
  ${companyDisplayFragment}
  ${PageInfoFullFragment}
`;

export const COMPANY_DEMO_DETAILS = gql`
  query companyDemoDetails($companyDemoId: ID!) {
    companyDemo(companyDemoId: $companyDemoId) {
      id: companyDemoId
      createdAt
      companyConnection {
        ...companyDisplayFragment
      }
      batchesConnection {
        id: batchNumber
        createdAt
        expiresAt
        title
        isLiveStatsEnabled
        rewardDefinitionConnection {
          id: rewardDefinitionId
          isEnabled
        }
        assignedBatchUsersConnection {
          pageInfo {
            ...PageInfoFullFragment
          }
        }
        userSelectionStrategyConnection {
          id: surveyBatchUserSelectionStrategyId
          createdAt
          description
          mappedCompaniesConnection {
            id: companyId
            createdAt
            includeInBatch
            includeInSpendShare
            companyConnection {
              ...companyDisplayFragment
            }
          }
        }
      }
      mappedUsersConnection {
        users {
          userConnection {
            id: userId
            displayName
            email
            mobilePhoneNumber
            createdAt
          }
        }
        pageInfo {
          ...PageInfoFullFragment
        }
      }
    }
  }
  ${companyDisplayFragment}
  ${PageInfoFullFragment}
`;

export const ASSIGNED_SURVEY_BATCH_USERS = gql`
  query assignedSurveyBatchUsers($batchNumber: Int!) {
    assignedSurveyBatchUsers(batchNumber: $batchNumber) {
      users {
        id: userId
        answerStatus
        createdAt
        userConnection {
          id: userId
          displayName
          featureFlagsConnection {
            featureFlagType
          }
        }
      }
      pageInfo {
        ...PageInfoFullFragment
      }
    }
  }
  ${PageInfoFullFragment}
`;

export const ASSIGN_DEMO_USERS_TO_DEMOBATCH = gql`
  mutation assignDemoUsersToDemoBatch($companyDemoId: ID!, $batchNumber: Int!) {
    assignDemoUsersToDemoBatch(
      companyDemoId: $companyDemoId
      batchNumber: $batchNumber
    )
  }
`;

export const SET_REWARD_DEFINITION_ENABLED = gql`
  mutation setRewardDefinitionEnabled(
    $rewardDefinitionId: ID!
    $isEnabled: Boolean!
  ) {
    setRewardDefinitionEnabled(
      rewardDefinitionId: $rewardDefinitionId
      isEnabled: $isEnabled
    ) {
      id: rewardDefinitionId
      isEnabled
    }
  }
`;

export const SET_ACTIVE_LIVE_STATS_BATCH = gql`
  mutation setActiveLiveStatsBatch($batchNumber: Int!) {
    setActiveLiveStatsBatch(batchNumber: $batchNumber) {
      id: batchNumber
      isLiveStatsEnabled
    }
  }
`;

export const ASSIGN_REAL_USERS_TO_DEMOBATCH = gql`
  mutation assignRealUsersToDemoBatch(
    $companyDemoId: ID!
    $batchNumber: Int!
    $userCountLimit: Int!
  ) {
    assignRealUsersToDemoBatch(
      companyDemoId: $companyDemoId
      batchNumber: $batchNumber
      userCountLimit: $userCountLimit
    )
  }
`;

export const MAP_COMPANY_DEMO_USER = gql`
  mutation mapCompanyDemoUser($companyDemoId: ID!, $userId: ID!) {
    mapCompanyDemoUser(companyDemoId: $companyDemoId, userId: $userId) {
      ...systemUserDetailsFragment
    }
  }
  ${systemUserDetailsFragment}
`;

export const UNMAP_COMPANY_DEMO_USER = gql`
  mutation unmapCompanyDemoUser($companyDemoId: ID!, $userId: ID!) {
    unmapCompanyDemoUser(companyDemoId: $companyDemoId, userId: $userId) {
      ...systemUserDetailsFragment
    }
  }
  ${systemUserDetailsFragment}
`;

export const ASSIGNABLE_SURVEY_BATCH_USERS = gql`
  query allAssignableSurveyBatchUsers(
    $batchNumber: Int!
    $paging: InputListPaging!
  ) {
    allAssignableSurveyBatchUsers(batchNumber: $batchNumber, paging: $paging) {
      users {
        id: userId
        genderCode
        ageYears
        createdDaysAgo
        activityDaysAgo
      }
      pageInfo {
        ...PageInfoFullFragment
      }
    }
  }
  ${PageInfoFullFragment}
`;

export const CONCLUDE_DEMO_BATCH = gql`
  mutation concludeDemoBatch($companyDemoId: ID!, $batchNumber: Int!) {
    concludeDemoBatch(
      companyDemoId: $companyDemoId
      batchNumber: $batchNumber
    ) {
      id: batchNumber
      expiresAt
    }
  }
`;

export const EXTEND_DEMO_BATCH = gql`
  mutation extendDemoBatch(
    $companyDemoId: ID!
    $batchNumber: Int!
    $newExpiresAt: String!
  ) {
    extendDemoBatch(
      companyDemoId: $companyDemoId
      batchNumber: $batchNumber
      newExpiresAt: $newExpiresAt
    ) {
      id: batchNumber
      expiresAt
    }
  }
`;

export const RESET_USER_SWIPE_BATCH_ANSWER_STATE = gql`
  mutation resetUserSwipeBatchAnswerState($batchNumber: Int!, $userId: ID!) {
    resetUserSwipeBatchAnswerState(batchNumber: $batchNumber, userId: $userId) {
      id: userId
      answerStatus
    }
  }
`;

export const ALL_CAMPAIGNS = gql`
  query allCampaigns($paging: InputListPaging!, $sort: InputListSort!) {
    allCampaigns(paging: $paging, sort: $sort) {
      campaigns {
        id: campaignId
        createdAt
        description
        companyConnection {
          ...companyDisplayFragment
        }
      }
      pageInfo {
        ...PageInfoFullFragment
      }
    }
  }
  ${PageInfoFullFragment}
  ${companyDisplayFragment}
`;

export const CAMPAIGN_INSTANCES = gql`
  query campaignInstances(
    $campaignId: ID!
    $paging: InputListPaging!
    $sort: InputListSort!
  ) {
    campaignInstances(campaignId: $campaignId, paging: $paging, sort: $sort) {
      instances {
        id: campaignInstanceId
        campaignId
        createdAt
        description
        slug
      }
      pageInfo {
        ...PageInfoFullFragment
      }
    }
  }
  ${PageInfoFullFragment}
`;

const accountsCreatedByCampaignMetricsFragment = gql`
  fragment accountsCreatedByCampaignMetricsFragment on AccountsCreatedByCampaignMetrics {
    invitationDegree
    totalCount
    createdBankConnectionCount
    syncedTransactionsCount
  }
`;

const accountsCreatedForCompanyMetricsFragment = gql`
  fragment accountsCreatedForCompanyMetricsFragment on AccountsCreatedForCompanyMetrics {
    totalCount
    averageAmountInCents
  }
`;

const surveyMetricsFragment = gql`
  fragment surveyMetricsFragment on SurveyMetrics {
    id: batchNumber
    mappedUsersCount
    notStartedCount
    startedCount
    completedCount
    surveyBatchConnection {
      id: batchNumber
      title
    }
  }
`;

export const CAMPAIGN_METRICS = gql`
  query campaignMetrics($campaignId: ID!) {
    campaignMetrics(campaignId: $campaignId) {
      accountsCreatedByCampaign {
        ...accountsCreatedByCampaignMetricsFragment
      }
      accountsCreatedForCompany {
        ...accountsCreatedForCompanyMetricsFragment
      }
      surveys {
        ...surveyMetricsFragment
      }
    }
  }
  ${accountsCreatedByCampaignMetricsFragment}
  ${accountsCreatedForCompanyMetricsFragment}
  ${surveyMetricsFragment}
`;

export const CAMPAIGN_INSTANCE_METRICS = gql`
  query campaignInstanceMetrics($campaignInstanceId: ID!) {
    campaignInstanceMetrics(campaignInstanceId: $campaignInstanceId) {
      accountsCreatedByCampaignInstance {
        ...accountsCreatedByCampaignMetricsFragment
      }
      accountsCreatedForCompany {
        ...accountsCreatedForCompanyMetricsFragment
      }
      surveys {
        ...surveyMetricsFragment
      }
    }
  }
  ${accountsCreatedByCampaignMetricsFragment}
  ${accountsCreatedForCompanyMetricsFragment}
  ${surveyMetricsFragment}
`;

export const CAMPAIGN = gql`
  query campaign($campaignId: ID!) {
    campaign(campaignId: $campaignId) {
      id: campaignId
      description
      companyConnection {
        ...companyDisplayFragment
      }
    }
  }
  ${companyDisplayFragment}
`;

export const CAMPAIGN_INSTANCE = gql`
  query campaignInstance($campaignInstanceId: ID!) {
    campaignInstance(campaignInstanceId: $campaignInstanceId) {
      id: campaignInstanceId
      description
    }
  }
`;

export const ASSIGN_USER_FEATURE_FLAG = gql`
  mutation assignUserFeatureFlag(
    $userId: ID!
    $featureFlagType: FeatureFlagType!
  ) {
    assignUserFeatureFlag(userId: $userId, featureFlagType: $featureFlagType) {
      id: userId
      featureFlagsConnection {
        featureFlagType
      }
    }
  }
`;

export const UNASSIGN_USER_FEATURE_FLAG = gql`
  mutation unassignUserFeatureFlag(
    $userId: ID!
    $featureFlagType: FeatureFlagType!
  ) {
    unassignUserFeatureFlag(
      userId: $userId
      featureFlagType: $featureFlagType
    ) {
      id: userId
      featureFlagsConnection {
        featureFlagType
      }
    }
  }
`;

export const KOSMA_CONSENTS = gql`
  query kosmaConsents($userId: ID!) {
    kosmaConsents(userId: $userId) {
      consents {
        id: kosmaConsentId
        createdAt
        validUntil
        kosmaBankConnection {
          id: kosmaBankKrn
          bankName
          logoUrl
        }
      }
      pageInfo {
        ...PageInfoFullFragment
      }
    }
  }
  ${PageInfoFullFragment}
`;

export const KOSMA_ACCOUNTS = gql`
  query kosmaAccounts($userId: ID!) {
    kosmaAccounts(userId: $userId) {
      accounts {
        id: kosmaHashedAccountId
        kosmaAccountId
        kosmaConsentId
        iban
        createdAt
        fetchedAt
        alias
      }
      pageInfo {
        ...PageInfoFullFragment
      }
    }
  }
  ${PageInfoFullFragment}
`;

export const CAMPAIGN_INSTANCE_USERS = gql`
  query campaignInstanceUsers(
    $campaignInstanceId: ID!
    $paging: InputListPaging!
  ) {
    campaignInstanceUsers(
      campaignInstanceId: $campaignInstanceId
      paging: $paging
    ) {
      users {
        id: userId
        assignedAt: createdAt
        userConnection {
          ...systemUserListFragment
        }
      }
      pageInfo {
        ...PageInfoFullFragment
      }
    }
  }
  ${PageInfoFullFragment}
  ${systemUserListFragment}
`;

export const SET_COMPANY_IS_VERIFIED = gql`
  mutation setCompanyIsVerified($companyId: ID!, $isVerified: Boolean!) {
    setCompanyIsVerified(companyId: $companyId, isVerified: $isVerified) {
      ...companyDisplayFragment
      isVerified
    }
  }
  ${companyDisplayFragment}
`;

export const UserVoucherFragment = gql`
  fragment UserVoucherFragment on UserVoucher {
    id: userVoucherId
    redeemedAt
    expiresAt
    isExpired
  }
`;

export const VoucherDefinitionFragment = gql`
  fragment VoucherDefinitionFragment on VoucherDefinition {
    id: voucherDefinitionId
    description
    imageUrl
    previewImageUrl
    information
    informationTitle
  }
`;

export const SwishRewardFragment = gql`
  fragment SwishRewardFragment on SwishReward {
    id: swishRewardId
    amount {
      ...AmountFragment
    }
    createdAt
    paidAt
    message
  }
  ${AmountFragment}
`;

export const SwishRewardDefinitionFragment = gql`
  fragment SwishRewardDefinitionFragment on SwishRewardDefinition {
    id: swishRewardDefinitionId
    description
    amount {
      ...AmountFragment
    }
  }
`;

export const RewardFragment = gql`
  fragment RewardFragment on Reward {
    id: rewardProgressId
    rewardDefinitionId
    title
    description
    earnedAt
    earnStatusDescription
    conditionsSummary {
      allUnmetConditions {
        conditionType
        conditionDescription
        metAt
        ... on RewardConditionStatusSurveyCompleted {
          batchNumber
          actionTitle
          actionEnabled
          actionVisible
        }
      }
    }
    companyConnection {
      ...companyDisplayFragment
    }
    userVoucherConnection {
      ...UserVoucherFragment
    }
    voucherDefinitionConnection {
      ...VoucherDefinitionFragment
    }
    swishRewardConnection {
      ...SwishRewardFragment
    }
    swishRewardDefinitionConnection {
      ...SwishRewardDefinitionFragment
    }
  }
  ${AmountFragment}
`;

export const RewardSpotlightFragment = gql`
  fragment RewardSpotlightFragment on RewardSpotlight {
    reward {
      ...RewardFragment
    }
    navigateToReward
    navigateToSurvey
  }
`;

export const USER_REWARDS = gql`
  query userRewards(
    $userId: ID!
    $includeExpired: Boolean
    $includeRedeemed: Boolean
  ) {
    userRewards(
      userId: $userId
      includeExpired: $includeExpired
      includeRedeemed: $includeRedeemed
    ) {
      rewards {
        ...RewardFragment
      }
      spotlight {
        ...RewardSpotlightFragment
      }
    }
  }
  ${AmountFragment}
  ${RewardSpotlightFragment}
  ${RewardFragment}
  ${SwishRewardFragment}
  ${SwishRewardDefinitionFragment}
  ${UserVoucherFragment}
  ${VoucherDefinitionFragment}
  ${companyDisplayFragment}
`;

export const BonusFragment = gql`
  fragment BonusFragment on ReferralBonus {
    id: referralBonusId
    title
    description
    companyConnection {
      ...companyDisplayFragment
    }
    conditionsSummary {
      isMet
    }
    voucherDefinitionConnection {
      ...VoucherDefinitionFragment
    }
    swishRewardDefinitionConnection {
      ...SwishRewardDefinitionFragment
    }
  }
`;

export const BonusEligibilityFragment = gql`
  fragment BonusEligibilityFragment on ReferralBonusEligibility {
    isEligible
    title
  }
`;

export const USER_REFERRAL_BONUSES = gql`
  query userReferralBonuses($userId: ID!) {
    userReferralBonuses(userId: $userId) {
      eligibility {
        ...BonusEligibilityFragment
      }
      bonuses {
        ...BonusFragment
      }
    }
  }
  ${AmountFragment}
  ${BonusFragment}
  ${BonusEligibilityFragment}
  ${companyDisplayFragment}
  ${SwishRewardDefinitionFragment}
  ${VoucherDefinitionFragment}
`;

export const FETCH_USER_TINK_TRANSACTIONS = gql`
  mutation FetchUserTinkTransactions($userId: ID!, $fromDate: String) {
    fetchUserTinkTransactions(userId: $userId, fromDate: $fromDate)
  }
`;

export const ENABLE_BANKING_SESSIONS = gql`
  query enableBankingSessions($userId: ID!) {
    enableBankingSessions(userId: $userId) {
      sessions {
        id: sessionId
        stateId
        createdAt
        validUntil
        updatedAt
        authorisedAt
        aspspConnection {
          id: aspspUid
          name
          logo
        }
        accountsConnection {
          pageInfo {
            ...PageInfoFullFragment
          }
          accounts {
            id: accountUid
            details
            createdAt
            fetchedAt
            earliestTransactionTimestamp
            latestTransactionTimestamp
            replacedByAccountUid
          }
        }
      }
      pageInfo {
        ...PageInfoFullFragment
      }
    }
  }
  ${PageInfoFullFragment}
`;

export const ENABLE_BANKING_ACCOUNT_FETCH_LOGS = gql`
  query enableBankingAccountFetchLogs(
    $accountId: ID!
    $paging: InputListPaging!
  ) {
    enableBankingAccountFetchLogs(accountUid: $accountId, paging: $paging) {
      transactionLogs {
        id: enableBankingTransactionStatusLogId
        enableBankingFetchSessionId
        createdAt
        statusCode
        details
        dateFrom
        dateTo
        transactionCount
        strategyDescription
      }
      pageInfo {
        ...PageInfoFullFragment
      }
    }
  }
  ${PageInfoFullFragment}
`;

export const FETCH_ENABLE_BANKING_ACCOUNT_TRANSACTIONS = gql`
  mutation fetchEnableBankingAccountTransactions(
    $accountUid: ID!
    $fromDate: String
  ) {
    fetchEnableBankingAccountTransactions(
      accountUid: $accountUid
      fromDate: $fromDate
    )
  }
`;

export const USER_VERIFIED_BRANDS = gql`
  query userVerifiedBrands($userId: ID!, $forceRefresh: Boolean!) {
    userVerifiedBrands(userId: $userId, forceRefresh: $forceRefresh) {
      companies {
        companies {
          ...companyDisplayFragment
          myConsumptionConnection {
            transactionTotalCount
          }
        }
        pageInfo {
          ...PageInfoFullFragment
        }
      }
    }
  }
  ${companyDisplayFragment}
  ${PageInfoFullFragment}
`;

export const USER_CACHED_TINK_CREDENTIALS = gql`
  query userCachedTinkCredentials($userId: ID!) {
    userCachedTinkCredentials(userId: $userId) {
      tinkCredentialId
      tinkUserId
      transactionsSyncedAt
      lastTransactionTimestamp
      createdAt
      providerName
      deletedAt
      lastKnownStatus
      lastKnownStatusUpdatedAt
    }
  }
`;

export const USER_MAPPED_CAMPAIGN_INSTANCES = gql`
  query userMappedCampaignInstances($userId: ID!) {
    userMappedCampaignInstances(userId: $userId) {
      instances {
        createdAt
        campaignInstanceConnection {
          id: campaignInstanceId
          description
          slug
          campaignConnection {
            id: campaignId
            description
            companyConnection {
              ...companyDisplayFragment
            }
          }
        }
      }
    }
  }
  ${companyDisplayFragment}
`;

export const USER_COMPANY_DEMOS = gql`
  query userCompanyDemos($userId: ID!) {
    userCompanyDemos(userId: $userId) {
      id: companyDemoId
      companyConnection {
        ...companyDisplayFragment
      }
    }
  }
  ${companyDisplayFragment}
`;

export const USER_FEATURE_FLAGS = gql`
  query userFeatureFlags($userId: ID!) {
    userFeatureFlags(userId: $userId) {
      featureFlagType
    }
  }
`;

export const PHONE_UPDATED_SYSTEM_ACTIVITY_LOGS = gql`
  query PhoneUpdatedSystemActivityLogs($userId: ID!) {
    allSystemActivityLogs(
      input: {
        filter: {
          categories: [user_updated_mobile_phone_number]
          primaryEntityId: $userId
        }
      }
    ) {
      systemActivityLogs {
        id: systemActivityLogId
        createdAt
        body
      }
      pageInfo {
        ...PageInfoFullFragment
      }
    }
  }
  ${PageInfoFullFragment}
`;

export const ALL_MARKETS = gql`
  query allMarkets {
    allMarkets {
      markets {
        id: marketId
        marketName
        iconUrl
        regionCode
      }
      pageInfo {
        ...PageInfoFullFragment
      }
    }
  }
  ${PageInfoFullFragment}
`;

export const SET_APP_TEXT_DOCUMENT_ARCHIVED = gql`
  mutation setAppTextDocumentArchived(
    $appTextDocumentById: ID!
    $archived: Boolean!
  ) {
    setAppTextDocumentArchived(
      appDocumentId: $appTextDocumentById
      archived: $archived
    ) {
      document {
        id: appDocumentId
        isArchived
      }
    }
  }
`;

export const LIST_ANSWER_REPORT_BATCHES = gql`
  query listAnswerReportBatches {
    listAnswerReportBatches {
      batches {
        batchNumber
        title
        createdAt
      }
      pageInfo {
        ...PageInfoFullFragment
      }
    }
  }
  ${PageInfoFullFragment}
`;

// batchAnswerReportPerQuestion
export const BATCH_ANSWER_REPORT_PER_QUESTION = gql`
  query batchAnswerReportPerQuestion(
    $batchNumber: Int!
    $paging: InputListPaging
  ) {
    batchAnswerReportPerQuestion(batchNumber: $batchNumber, paging: $paging) {
      rows {
        userId
        swipeCardId
        swipeCardAnswerId
        questionMarkdown
        answeredAt
        answerChoice
        answerFreeText
        sortOrder
        userConnection {
          id: userId
          ageYears
          email
          mobilePhoneNumber
          postalCode
          gender
        }
      }
      pageInfo {
        ...PageInfoFullFragment
      }
    }
  }
  ${PageInfoFullFragment}
`;

const BalancePayoutBankAccountRequestFragment = gql`
  fragment BalancePayoutBankAccountRequestFragment on BalancePayoutBankAccountRequest {
    id: balancePayoutRequestId
    requestedAt
    completedAt
    balancePayoutBankAccountConnection {
      id: balancePayoutBankAccountId
      bankName
      logoUrl
      fullAccountNumber
    }
    balanceTotalAmount {
      ...AmountFragment
    }
    requestedByUserConnection {
      id: userId
      displayName
      email
      mobilePhoneNumber
    }
    status
  }
`;

export const BALANCE_PAYOUT_BANK_ACCOUNT_REQUESTS = gql`
  query allBalancePayoutBankAccountRequests(
    $filter: BalancePayoutBankAccountRequestsFilter!
    $paging: InputListPaging!
  ) {
    allBalancePayoutBankAccountRequests(filter: $filter, paging: $paging) {
      requests {
        ...BalancePayoutBankAccountRequestFragment
      }
      pageInfo {
        ...PageInfoFullFragment
      }
    }
  }
  ${AmountFragment}
  ${BalancePayoutBankAccountRequestFragment}
  ${PageInfoFullFragment}
`;

export const BalanceItemFragment = gql`
  fragment BalanceItemFragment on BalanceItem {
    id: balanceItemId
    createdAt
    description
    amount {
      ...AmountFragment
    }
  }
`;

export const BALANCE_PAYOUT_BANK_ACCOUNT_REQUEST_DETAILS = gql`
  query balancePayoutBankAccountRequestDetails($balancePayoutRequestId: ID!) {
    balancePayoutBankAccountRequest(
      balancePayoutRequestId: $balancePayoutRequestId
    ) {
      id: balancePayoutRequestId
      balanceItems {
        items {
          ...BalanceItemFragment
        }
        pageInfo {
          ...PageInfoFullFragment
        }
      }
    }
  }
  ${BalanceItemFragment}
  ${AmountFragment}
  ${PageInfoFullFragment}
`;

export const MARK_BALANCE_PAYOUT_BANK_ACCOUNT_REQUEST_COMPLETED = gql`
  mutation markBalancePayoutBankAccountRequestCompleted(
    $balancePayoutRequestId: ID!
    $isCompleted: Boolean!
  ) {
    markBalancePayoutBankAccountRequestCompleted(
      balancePayoutRequestId: $balancePayoutRequestId
      isCompleted: $isCompleted
    ) {
      ...BalancePayoutBankAccountRequestFragment
    }
  }
  ${AmountFragment}
  ${BalancePayoutBankAccountRequestFragment}
`;
