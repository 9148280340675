import React, { useCallback, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { BalancePayoutStatus } from "../../../graphql/types/global";
import {
  BALANCE_PAYOUT_BANK_ACCOUNT_REQUESTS,
  MARK_BALANCE_PAYOUT_BANK_ACCOUNT_REQUEST_COMPLETED,
} from "../../../graphql/queries.gql";
import {
  allBalancePayoutBankAccountRequests,
  allBalancePayoutBankAccountRequestsVariables,
} from "../../../graphql/types/allBalancePayoutBankAccountRequests";
import PayoutRequestsFilter from "./PayoutRequestsFilter";
import PayoutRequestsTable from "./PayoutRequestsTable";
import { Snackbar } from "@material-ui/core";
import {
  markBalancePayoutBankAccountRequestCompleted,
  markBalancePayoutBankAccountRequestCompletedVariables,
} from "../../../graphql/types/markBalancePayoutBankAccountRequestCompleted";

const PayoutRequestsPage: React.FC = () => {
  const [snackbarState, setSnackbarState] = React.useState({
    open: false,
    message: "",
  });
  const [status, setStatus] = useState<BalancePayoutStatus | null>(
    BalancePayoutStatus.requested
  );
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);

  const { data, refetch, loading } = useQuery<
    allBalancePayoutBankAccountRequests,
    allBalancePayoutBankAccountRequestsVariables
  >(BALANCE_PAYOUT_BANK_ACCOUNT_REQUESTS, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: {
      filter: { status },
      paging: { page, size: pageSize },
    },
  });

  const [setCompletedMutation, { loading: completionLoading }] = useMutation<
    markBalancePayoutBankAccountRequestCompleted,
    markBalancePayoutBankAccountRequestCompletedVariables
  >(MARK_BALANCE_PAYOUT_BANK_ACCOUNT_REQUEST_COMPLETED);

  const handleRefetch = useCallback(() => refetch(), [refetch]);

  const handleRequestPageSize = useCallback(
    (pageSize: number) => {
      setPageSize(pageSize);
      setPage(1);
    },
    [setPage, setPageSize]
  );

  const handleRequestPage = useCallback(
    (page: number) => setPage(page),
    [setPage]
  );

  const handleRequestSetStatus = useCallback(
    (status: BalancePayoutStatus | null) => {
      setPage(1);
      setStatus(status);
    },
    [setStatus]
  );

  const handleSnackbarClose = useCallback(() => {
    setSnackbarState((current) => ({ ...current, open: false }));
  }, [setSnackbarState]);

  const handleSetIsCompleted = useCallback(
    async (args: { balancePayoutRequestId: string; isCompleted: boolean }) => {
      try {
        const response = await setCompletedMutation({ variables: args });
        const row = response.data?.markBalancePayoutBankAccountRequestCompleted;
        setSnackbarState({
          open: true,
          message: `Request "${row?.id}" marked as ${row?.status}`,
        });
      } catch (err: any) {
        console.error(err);
        setSnackbarState({
          open: true,
          message: "Failed to mark request as completed: " + err?.message,
        });
      }
    },
    [setCompletedMutation]
  );

  const isLoading = loading || completionLoading;

  return (
    <div>
      <PayoutRequestsFilter
        status={status}
        onRequestSetStatus={handleRequestSetStatus}
        onRequestRefetch={handleRefetch}
        loading={isLoading}
      />
      {data ? (
        <PayoutRequestsTable
          loading={isLoading}
          requests={data.allBalancePayoutBankAccountRequests.requests}
          pageInfo={data.allBalancePayoutBankAccountRequests.pageInfo}
          onRequestRefetch={handleRefetch}
          onRequestPage={handleRequestPage}
          onRequestPageSize={handleRequestPageSize}
          onRequestSetCompleted={handleSetIsCompleted}
        />
      ) : null}
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        open={snackbarState.open}
        onClose={handleSnackbarClose}
        message={snackbarState.message}
        autoHideDuration={4000}
      />
    </div>
  );
};

export default PayoutRequestsPage;
