import React from "react";
import { Link, useLocation } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import TextFormatIcon from "@material-ui/icons/TextFormat";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AccountBalanceOutlinedIcon from "@material-ui/icons/AccountBalanceOutlined";
import AssignmentIcon from "@material-ui/icons/Assignment";
import RoomIcon from "@material-ui/icons/Room";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PersonIcon from "@material-ui/icons/Person";
import StorefrontIcon from "@material-ui/icons/Storefront";
import BarChartIcon from "@material-ui/icons/BarChart";
import ListIcon from "@material-ui/icons/List";

import { makeStyles } from "@material-ui/core/styles";
import { useDrawerContext } from "../../contexts/DrawerContext";
import { useAllMarkets } from "../../utils/useAllMarkets";
import { MarketIcon } from "../../components/MarketIcon";

interface WithDrawerProps {
  children?: React.ReactNode;
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  withAdminDrawerRoot: {
    display: "flex",
    flexGrow: 1,
  },
  drawerContainer: {
    overflow: "auto",
  },
  drawer: {
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    [theme.breakpoints.up("lg")]: {
      paddingLeft: drawerWidth,
    },
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  list: {
    paddingTop: 0,
  },
}));

interface MenuListItemProps {
  path: string;
  icon: React.ReactNode;
  endIcon?: React.ReactNode;
  text: string;
}

const useListItemStyles = makeStyles((theme) => ({
  activeListItem: {
    borderLeft: `${theme.spacing(1)}px solid ${theme.palette.secondary.main}`,
  },
  listItem: {
    paddingLeft: `${theme.spacing(3)}px`,
  },
}));

const MenuListItem: React.FC<MenuListItemProps> = ({
  path,
  icon,
  text,
  endIcon,
}) => {
  const classes = useListItemStyles();
  const location = useLocation();

  return (
    <ListItem
      button
      component={Link}
      to={`${path}`}
      className={
        location.pathname === path ? classes.activeListItem : classes.listItem
      }
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={text} />
      {endIcon}
    </ListItem>
  );
};

const WithAdminDrawer: React.FC<WithDrawerProps> = ({ children }) => {
  const drawerContext = useDrawerContext();
  const [markets] = useAllMarkets();

  const classes = useStyles();
  if (!children) {
    return null;
  }
  const drawerContent = (
    <div>
      <Toolbar />
      <div className={classes.drawerContainer}>
        <List className={classes.list}>
          {markets?.markets.map((m) => (
            <React.Fragment key={m.id}>
              <MenuListItem
                path={`/admin/activity/${m.id}`}
                icon={<RoomIcon />}
                endIcon={<MarketIcon market={m} />}
                text="Activity log"
              />
              <MenuListItem
                path={`/admin/users/${m.id}`}
                icon={<PersonIcon />}
                endIcon={<MarketIcon market={m} />}
                text="Users"
              />
            </React.Fragment>
          ))}
          <MenuListItem
            path="/admin/assign"
            icon={<AssignmentIcon />}
            text="Assign"
          />
          <MenuListItem
            path="/admin/documents"
            icon={<TextFormatIcon />}
            text="Documents"
          />
          <MenuListItem
            path="/admin/disbursements"
            icon={<AccountBalanceIcon />}
            text="Disbursements"
          />
          <MenuListItem
            path="/admin/payouts"
            icon={<AccountBalanceOutlinedIcon />}
            text="Payout requests"
          />
          <MenuListItem
            path="/admin/demos"
            icon={<StorefrontIcon />}
            text="Demos"
          />
          <MenuListItem
            path="/admin/campaigns"
            icon={<BarChartIcon />}
            text="Campaigns"
          />
          <MenuListItem
            path="/admin/batchreports"
            icon={<ListIcon />}
            text="Batch reports"
          />
        </List>
      </div>
    </div>
  );

  return (
    <div className={classes.withAdminDrawerRoot}>
      <nav className={classes.drawer}>
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            open={drawerContext.isOpen}
            onClose={drawerContext.toggleOpen}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawerContent}
          </Drawer>
        </Hidden>
        <Hidden mdDown implementation="css">
          <Drawer
            className={classes.drawer}
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="persistent"
            anchor="left"
            open
          >
            {drawerContent}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>{children}</main>
    </div>
  );
};

export default WithAdminDrawer;
